import React from "react"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import MyButton from "../tools/MyButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import CheckIcon from '@material-ui/icons/Check';
import { useIntl, navigate } from "gatsby-plugin-intl"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  iconroot: {
    minWidth: 36,
    color: blue[500]
  }
}));

const IndexPage = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const data = useStaticQuery(graphql `
    query {
      intelligentImage: file(relativePath: { eq: "intelligent.png" }) {
        childImageSharp {
          fixed(width: 172, height: 172) {
          ...GatsbyImageSharpFixed
          }
        }
      }
      templateImage: file(relativePath: { eq: "template.png" }) {
        childImageSharp {
          fixed(width: 172, height: 172) {
          ...GatsbyImageSharpFixed
          }
        }
      }
      marketingImage: file(relativePath: { eq: "marketing.png" }) {
        childImageSharp {
          fixed(width: 172, height: 172) {
          ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
      <Layout>
        <SEO title={intl.formatMessage({id: "home.title2"})} />
        <header style={{position: 'relative', margin: '-24px', padding: '24px', background: 'white'}}>
          <div style={{
            marginTop: 10,
            maxWidth: '300',
            bottom: 0,
            position: 'absolute',
            right: -10,
            width: 488,
            zIndex: 0,
          }}>
            <Image />
          </div>
            <List style={{marginTop: 40}}>
              <Typography style={{marginLeft: 16}} component="h1" variant="h1">
                <Typography style={{color: "#ccc", marginBottom: 10}} component="p" variant="h3">{intl.formatMessage({id: "home.title1"})}</Typography>
                <Typography style={{color: blue[500], marginBottom: 10}} component="p" variant="h3">{intl.formatMessage({id: "home.title2"})}</Typography>
              </Typography>
              <ListItem>
                <ListItemIcon classes={{root: classes.iconroot}}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({id: "home.subtitle1"})} />
              </ListItem>
              <ListItem>
                <ListItemIcon classes={{root: classes.iconroot}}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({id: "home.subtitle2"})} />
              </ListItem>
              <ListItem>
                <ListItemIcon classes={{root: classes.iconroot}}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({id: "home.subtitle3"})} />
              </ListItem>
              <MyButton onClick={() => navigate("/app/login")} style={{margin: '24px 16px', width: '160px'}} color="primary" variant="contained" id="calltoaction">{intl.formatMessage({id: "pric.getstarted"})}</MyButton>
          </List>
        </header>
        <section style={{
          margin: -24,
          padding: 24,
          marginTop: 24}}>
        <div style={{textAlign: 'center', marginBottom: 48}}>
          <Typography style={{padding: '48px 0'}} component="h1" variant="h4">{intl.formatMessage({id: "home.why"})}</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Paper elevation={0} className={classes.paper}>
                <Img fixed={data.intelligentImage.childImageSharp.fixed} />
                <Typography style={{padding: '24px 40px 12px', fontWeight: 600}} component="h2" variant="h6">
                  {intl.formatMessage({id: "home.whytitle1"})}
                </Typography>
                <Typography style={{padding: '0 40px'}} component="p" variant="body1">
                  {intl.formatMessage({id: "home.whydesc1"})}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper elevation={0} className={classes.paper}>
                <Img fixed={data.marketingImage.childImageSharp.fixed} />
                <Typography style={{padding: '24px 40px 12px', fontWeight: 600}} component="h2" variant="h6">
                  {intl.formatMessage({id: "home.whytitle2"})}
                </Typography>
                <Typography style={{padding: '0 40px'}} component="p" variant="body1">
                  {intl.formatMessage({id: "home.whydesc2"})}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper elevation={0} className={classes.paper}>
                <Img fixed={data.templateImage.childImageSharp.fixed} />
                <Typography style={{padding: '24px 40px 12px', fontWeight: 600}} component="h2" variant="h6">
                  {intl.formatMessage({id: "home.whytitle3"})}
                </Typography>
                <Typography style={{padding: '0 40px'}} component="p" variant="body1">
                  {intl.formatMessage({id: "home.whydesc3"})}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>
        </section>
    </Layout>
  )
}

export default IndexPage